<template>
  <div>

    <app-step step="1" />

    <div class="container pt-4">
      <div class="row px-3">
        <div class="col-sm-3 border border-bottom-0">
          <div class="row text-primary d-flex justify-content-center">
            <div class="col-2 my-auto py-2"><h4 class="my-0"><i class="fad fa-file-invoice"></i></h4></div>
            <div class="col-8 my-auto py-2"><h5 class="my-0">Facturación</h5></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-5">

      <hr class="mt-0">
      
      <div class="row d-flex justify-content-between">
        <b-form class="container">
          
          <div class="row px-3">
            <b-form-input
              v-model="company"
              class="col-12 mb-2 mr-sm-2"
              placeholder="Razon Social"
            ></b-form-input>
          </div>
          
          <div class="row d-flex justify-content-between px-3">
            <b-form-input
              v-model="rfc"
              class="col-sm-5 mb-2"
              placeholder="RFC"
            ></b-form-input>

            <b-form-select 
              v-model="cfdi" 
              class="col-sm-6 mb-2"
              :options="options"
            ></b-form-select>
          </div>
        
        </b-form>
      </div>

      <div class="row mt-5">
        <div class="col-sm-4 ml-auto">
          <b-button v-on:click="go_to(1)" variant="primary" block>Siguiente</b-button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Invoice',
  data() {
    return {
      company: '',
      rfc: '',
      cfdi: '',
      options: ''
    }
  },
  components: {
    AppStep: () => import('@/components/AppStep.vue')
  },
  methods: {
    go_to(id) {
      var invoice = {
        required: true,
        company: this.company,
        rfc: this.rfc,
        cfdi: this.cfdi
      }
      
      this.$store.commit('set_invoice', invoice)

      switch (id) {
        case 1: this.$router.push('/payment'); break;
      }
    }
  },
  created() {
    this.company = ''
    this.rfc = ''
    this.cfdi = null

    this.options = [
      { value: null, text: 'Uso de CFDI'},
      { value: 'G01', text: 'Adquisición de mercancías'},
      { value: 'G02', text: 'Devoluciones, descuentos o bonificaciones'},
      { value: 'G03', text: 'Gastos en general'},
      { value: 'I01', text: 'Construcciones'},
      { value: 'I02', text: 'Mobiliario y equipo de oficina por inversiones'},
      { value: 'I03', text: 'Equipo de transporte'},
      { value: 'I04', text: 'Equipo de cómputo y accesorios'},
      { value: 'I05', text: 'Datos, troqueles, moldes, matrices y herramental'},
      { value: 'I06', text: 'Comunicaciones telefónicas'},
      { value: 'I07', text: 'Comunicaciones satelitales'},
      { value: 'I08', text: 'Otra maquinaria y equipo'},
      { value: 'D01', text: 'Honorarios médicos, dentales y gastos hopitalarios'},
      { value: 'D02', text: 'Gastos médicos por incapacidad o discapacidad'},
      { value: 'D03', text: 'Gastos funerales'},
      { value: 'D04', text: 'Donativos'},
      { value: 'D05', text: 'Intereses reales efectivamente pagados por créditos hipotecarios'},
      { value: 'D06', text: 'Aportaciones voluntarias al SAR'},
      { value: 'D07', text: 'Primas por seguros de gastos médicos'},
      { value: 'D08', text: 'Gastos de transportación escolar obligatoria'},
      { value: 'D09', text: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones'},
      { value: 'D10', text: 'pagos por servicios educativos (colegiaturas)'},
      { value: 'P01', text: 'Por definir'},
    ]
  },
}
</script>